import React from 'react'
import '../assets/styles/Pricing.css'
import { ReactComponent as CheckMark } from '../assets/icons/check_circle.svg'

const COLORS = {
	GREEN: '#619E30',
	BROWN: '#A27D1D'
}

const PRICE_PLAN = [
  {
		title: 'Product Transfer (with Existing Lender)',
		price: 'FREE',
		description: 'Free of charge! No advice fee',
		note: 'You will still have access to the following however, if it is in your best interest or right choice for you, we won’t charge any advice fee for Product transfer application.',
		covers: ['Access to 100+ lenders', "Access to 1000's of products", 'Expert mortgage advice']
	},
	{
		title: 'NHS, Armed Forces & Existing Client',
		price: '25% Off',
		description: 'Discounted rates',
		covers: [
			'First time buyer',
			'Buy-To-Let',
			'Re-Mortgages (Product transfer included)',
			'Limited company Buy-to-let',
			'Access to 100+ lenders',
			"Access to 1000's of products",
			'Expert mortgage advice'
		]
	},
	{
		title: 'Re-mortgage with other lender',
		price: '£295',
		description: 'Per application',
		covers: ['Includes Capital raise', 'Includes Transfer of equity', 'Access to 100+ lenders', "Access to 1000's of products", 'Expert mortgage advice']
	},
	{
		title: 'Purchase',
		price: '£495',
		description: 'Per application',
		covers: ['First time buyer', 'Buy-To-Let', 'Limited company Buy-to-let', 'Access to 100+ lenders', "Access to 1000's of products", 'Expert mortgage advice']
	}
]

const Cover = ({ cover, color }) => (
	<li>
		<CheckMark fill={color} /> <span>{cover}</span>
	</li>
)

const PricingCard = ({ plan }) => {
	const { title, price, description, note, covers } = plan
	const free = price === 'FREE'
	const cardClass = free ? 'secondary' : 'primary'
	const cardOutlineColor = free ? COLORS.GREEN : COLORS.BROWN
	return (
		<div className={`pricing__cards-container__card pricing__cards-container__card--${cardClass}`}>
			<div className="pricing__cards-container__card__sections">
				<h4 className="pricing__cards-container__card__section__heading">{title}</h4>
				<div className="pricing__cards-container__card__section">
					<h3 className={`pricing__cards-container__card__section__price--${cardClass}`}>{price}</h3>
					<p className="pricing__cards-container__card__section__description">{description}</p>
					{note && <p className="pricing__cards-container__card__section__note">{note}</p>}
					<div className="pricing__cards-container__card__cover">
						{covers.map((cover, index) => (
							<Cover cover={cover} color={cardOutlineColor} key={index} />
						))}
					</div>
				</div>
			</div>
			<button className={cardClass} onClick={() => (window.location.href = 'tel:01483339247')}>
				Get Started
			</button>
		</div>
	)
}

export const Pricing = () => {
	return (
		<section id="pricing" className="pricing">
				<div className="pricing__heading">
					<h3>Simple Pricing Structure</h3>
					<h4>No Hidden Charges!</h4>
					<p>Transparent and fair pricing for your peace of mind. No surprises, just straightforward solutions tailored to you.</p>
				</div>
				<div className="pricing__cards-container">
					{PRICE_PLAN.map((plan, index) => (
						<PricingCard plan={plan} key={index} />
					))}
				</div>
			<h4 className="pricing__note">Your Home may be repossessed if you do not keep up repayments on your mortgage.</h4>
		</section>
	)
}
