import React from 'react'
import '../../assets/styles/input-results/results-field.css'

function currencyFormat(num) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const ResultsField = ({ label, value }) => {
	return (
		<div className="results-field">
			<p className="results-field__label">{label}</p>
			<p className="results-field__value bold">£{currencyFormat(Math.round(value))}</p>
		</div>
	)
}
