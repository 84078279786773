import React, { useEffect, useState } from 'react'
import '../assets/styles/Hero.css'
import heroTextIcon from '../assets/icons/hero-text-icon.svg'

import HeroHover from './HeroHover'

export const Hero = () => {
	const [active, isActive] = useState(false)

	function parallax(e) {
		this.querySelectorAll('.layer').forEach((layer) => {
			const speed = layer.getAttribute('data-speed')
			const x = (window.innerWidth - e.pageX * speed) / 100
			const y = (window.innerHeight - e.pageY * speed) / 100
			layer.style.transform = `translateX(${x}px) translateY(${y}px)`
		})
	}

	useEffect(() => {
		document.addEventListener('mousemove', parallax)
		isActive(true)

		return () => {
			document.removeEventListener('mousemove', parallax)
		}
	}, [])

	return (
		<div className="hero ">
			<div className="common--container">
				<div className="hero__flex">
					<div className={` hero__maintext animate ${active ? 'active' : ''}`}>
						<h1 className="bold ">
							<span className="red">Explore</span> the <img className="hero__maintext__icon" src={heroTextIcon} alt="hero-text-icon" /> <br />
							Market, Unlock <br />
							<span className="gradient">Competitive Rates</span>
						</h1>
						<p>{'Hamro Mortgages Vs Traditional firms\nThe First Nepalese Hybrid Mortgage Firm in the UK!'}</p>
						<button className="hero__button" onClick={() => (window.location.href = 'tel:01483339247')}>
							Contact Us
						</button>
					</div>
					<HeroHover />
				</div>
			</div>
		</div>
	)
}
