import React from 'react'
import '../assets/styles/AboutUs.css'
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import LogoHybrid from '../assets/images/hm-logo-hybrid-text.svg'

import headingImage from '../assets/images/about-us-image.png'
import quoteIcon from '../assets/icons/hm-quote-icon.png'

const WhoWhatData = {
	whoAreWe: [
		{
			title: 'We are a dynamic and innovative',
			description:
				'hybrid mortgage firm that is not only dedicated to providing top-notch financial services to our clients but also committed to reducing our carbon footprint.'
		},
		{
			title: 'We embrace changes',
			description:
				'that combines traditional practices with modern technology, which allows us to reduce our own carbon footprint and pass on the savings to our clients by lowering our mortgage advice fee.'
		},
		{
			title: 'With Hamro Mortgages,',
			description: 'not only will you receive the best of both worlds through our unique blend of online convenience and personal advisor expertise.'
		},
		{
			title: 'You can rest assured',
			description: 'that you are working with a company that is committed to reducing its environmental impact.'
		}
	],
	whatWeDo: [
		{
			title: 'We specialize in Armed Forces personnel, NHS employees,',
			description:
				'and those with non-standard visa status. We understand the unique challenges faced by these individuals and have the expertise to provide tailored mortgage solutions that meet their specific needs.'
		},
		{
			title: 'We break down barriers for the Nepalese diaspora',
			description: 'and other ethnic minorities in the UK who often encounter challenges in securing mortgages through standard lender criteria.'
		},
		{
			title: 'Our experienced professionals',
			description:
				"blends industry expertise with cutting-edge technology to deliver personalized mortgage solutions for every client. Whether you're a homebuyer or looking to re-mortgage, we're here to help you achieve your goals with confidence."
		}
	],
	ourTechnology: [
		{
			title: 'Innovative Automation',
			description:
				'has enabled us to automate processes that can be automated, allowing our team to focus on building relationships with our clients and performing more advanced problem-solving tasks.'
		},
		{
			title: 'Delivering Value',
			description: 'through efficient services, reduced costs and maximized benefits for our clients.'
		},
		{
			title: 'Client-Focused Productivity',
			description: 'by leveraging technology, we provide tailored mortgage solutions that meet the unique needs and objectives of our clients.'
		},
		{
			title: 'Environmental Responsibility',
			description: 'is a core value of ours, and we strive to make a positive impact through sustainable business practices.'
		}
	]
}

const WhoWhatCard = ({ title, description }) => (
	<div className="about-us-who-what__details__card">
		<h5>{title}</h5>
		<p>{description}</p>
	</div>
)

const AboutUs = () => {
	return (
		<>
			<Navbar />

			<div className="about-us ">
				<div className="common--container">
					<div className="about-us__heading-container">
						<div className="about-us__heading-container__top-section">
							<div className="about-us__circle">
								<img src={LogoHybrid} alt="logo-hybrid" className="about-us__name" />
								<img src={'/favicon.svg'} alt="logo-hybrid" className="about-us__logo" />
							</div>

							<h1>About Us</h1>
							<p className="empower">Empowering homeownership with innovative technology solutions</p>
						</div>
						<img className="about-us__heading-container__image" src={headingImage} alt="logo-hybrid" />
					</div>
					<div className="about-us-who-what">
						<h1>WHO ARE WE</h1>
						<div className="about-us-who-what__details">
							{WhoWhatData.whoAreWe.map((data, index) => (
								<WhoWhatCard title={data.title} description={data.description} key={index} />
							))}
						</div>
					</div>
					<div className="about-us-who-what">
						<h1>WHAT WE DO</h1>
						<div className="about-us-who-what__details">
							{WhoWhatData.whatWeDo.map((data, index) => (
								<WhoWhatCard title={data.title} description={data.description} key={index} />
							))}
						</div>
					</div>
					<div className="about-us-who-what">
						<h1>OUR TECHNOLOGY</h1>
						<div className="about-us-who-what__details">
							{WhoWhatData.ourTechnology.map((data, index) => (
								<WhoWhatCard title={data.title} description={data.description} key={index} />
							))}
						</div>
					</div>
					<div className="about-us-quote">
						<img src={quoteIcon} alt="logo-hybrid" />
						<p className="about-us-quote__text">
							“That is when and how we will <u>deliver</u> the <u>highest value</u> to our clients.”
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default AboutUs
