import React from 'react'
import '../assets/styles/InfoCard.css'

export const InfoCard = ({ cardInfo }) => {
	return (
		<div className={`info-card ${cardInfo.additionalClass || ''}`}>
			<div className="info-card__main">
				<img className="info-card__icon" src={cardInfo.icon} alt={cardInfo.iconAltText} height={cardInfo.height} />
				<div className="info-card__details">
					<p className="info-card__heading bold">{cardInfo.heading}</p>
					<p className="info-card__description">{cardInfo.description}</p>
				</div>
			</div>
			{cardInfo.otherIcons && (
				<div className="info-card__additional-icons">
					{cardInfo.otherIcons.map((subIcon, index) => (
						<img className="info-card__icon" src={subIcon.icon} alt={subIcon.iconAltText} height={subIcon.height} key={index} />
					))}
				</div>
			)}
		</div>
	)
}
