import React from 'react'
import '../assets/styles/Footer.css'
import logo from '../assets/images/hm-footer-logo-text.svg'
import callIcon from '../assets/icons/call-icon.png'
import mailIcon from '../assets/icons/mail-icon.png'
import locationIcon from '../assets/icons/location_icon.png'
import facebookIcon from '../assets/icons/facebook-icon.png'
import instagramIcon from '../assets/icons/instagram-icon.png'
import linkedInIcon from '../assets/icons/linkedin-icon.png'

export const Footer = () => {
	return (
		<footer className="footer-container">
			<div className="footer common--container">
				<div className="footer__upper-content">
					<div className="footer__upper-content__company">
						<div className="logo">
							<a href="/">
								<img src={logo} alt="logo-footer" />
							</a>
						</div>
						<p>
							Hamro Mortgages Ltd, trading as Hamro Mortgages is an appointed representative of HL Partnership Limited which is authorised and regulated by the Financial
							Conduct Authority. Hamro Mortgages Ltd is registered in England and Wales with Registration number 14500349. Registered Office: Flat 30 Olympian Heights,
							Guildford Road, Woking, Surrey, GU22 7RN
						</p>
					</div>
					<div className="footer__upper-content__opening-hours">
						<p className="bold white">Opening hours</p>
						<p>Monday to Friday – 9AM to 5PM</p>
						<p className="red">Saturday – Closed</p>
						<p className="red">Sunday – Closed</p>
					</div>
					<div className="footer__upper-content__contact-us">
						<p className="bold white">Contact us</p>
						<div className="footer__upper-content__contact-us__information">
							<li>
								<img src={callIcon} alt="call-icon" />
								<a href="tel:01483339247">
									<span>01483 339247</span>
								</a>
							</li>
							<li>
								<img src={mailIcon} alt="main-icon" />
								<a href="mailto:hello@hamromortgages.co.uk">
									<span>hello@hamromortgages.co.uk</span>
								</a>
							</li>
							<li>
								<img src={locationIcon} alt="location-icon" />
								<span>Flat 30 Olympian Heights, Guildford Road, Woking, Surrey, GU22 7RN</span>
							</li>
						</div>
					</div>
				</div>
				<div className="footer__lower-content">
					<div className="footer__lower-content__first">
						<p className="footer__lower-content__first__address">
							© 2023 Hamro Mortgages Ltd. All rights reserved. Registered address: Flat 30 Olympian Heights, Guildford Road, Woking GU22 7RN.
							<br />
							<br />
							Registered in England & Wales under number 14500349.
						</p>
						<div className="footer__lower-content__first__social">
							<p>Get in touch:</p>
							<a href="https://www.facebook.com/hamromortgages" target="_blank" rel="noreferrer">
								<img src={facebookIcon} alt="facebook-icon" />
							</a>
							<a href="https://www.instagram.com/hamromortgages" target="_blank" rel="noreferrer">
								<img src={instagramIcon} alt="instagram-icon" />
							</a>
						</div>
					</div>
					<div className="footer__lower-content__second">
						<p className="footer__lower-content__second__privacy">
							The Financial Conduct Authority does not regulate some forms of Buy to Lets. Your property may be repossessed if you do not keep up repayments on your
							mortgage.
							<br />
							<br />
							<a href="/docs/complaints_procedure.pdf" target="_blank" rel="noreferrer">
								Complaints Procedure
							</a>
							&nbsp; | &nbsp;
							<a href="/docs/cookies_policy.pdf" target="_blank" rel="noreferrer">
								Cookies Policy
							</a>
							&nbsp; | &nbsp;
							<a href="/docs/data_protection.pdf" target="_blank" rel="noreferrer">
								Data Protection
							</a>
							<br />
							<br />
							The guidance and/or information contained within this website is subject to the UK regulatory regime and is therefore targeted at consumers based in the UK.
						</p>
					</div>
				</div>
			</div>
			<div style={{ backgroundImage: "url('/assets/footer.png')" }} className="footer__bottom"></div>
		</footer>
	)
}
