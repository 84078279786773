import React from 'react'
import { NavLink } from 'react-router-dom'
import '../assets/styles/Navbar.css'
import logo from '../assets/images/hm-logo-text.svg'
import { useEffect } from 'react'

export const Navbar = () => {
	const scrollToSection = () => {
		const currentLocation = window.location.href
		const hasScrollAnchor = currentLocation.includes('/#')

		if (hasScrollAnchor) {
			const anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
			const anchorSection = document.getElementById(anchorSectionId)
			if (anchorSection) {
				anchorSection.scrollIntoView()
			}
		}
	}

	useEffect(() => {
		scrollToSection()
	}, [])

	return (
		<header className="navbar ">
			<div className="nav__container">
				<div className="logo">
					<a href="/">
						<img src={logo} alt="logo" />
					</a>
				</div>
				<div className="navbar__list">
					<li>
						<NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
							<span>Home</span>
							<img src="/assets/active-line.svg" alt="" className="indicator" />
						</NavLink>
					</li>
					<li>
						<a href="/#why-choose-us">Why Us</a>
					</li>
					<li>
						<a href="/#digital-calculator">Calculator</a>
					</li>
					<li>
						<a href="/#why-hybrid">Why Hybrid</a>
					</li>
					<li>
						<a href="/#pricing">Pricing</a>
					</li>
					<li>
						<NavLink to="/about-us" className={({ isActive }) => (isActive ? 'active' : '')}>
							<span>About Us</span>
							<img src="/assets/active-line.svg" alt="" className="indicator" />
						</NavLink>
					</li>
				</div>
			</div>
		</header>
	)
}
