import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './assets/styles/index.css'
import Home from './components/Home'
import AboutUs from './components/AboutUs'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		errorElement: <Home />
	},
	{
		path: '/about-us',
		element: <AboutUs />
	}
])

ReactDOM.createRoot(document.getElementById('root')).render(
	<>
		<RouterProvider router={router} />
	</>
)
