import React from 'react'
import '../assets/styles/WhyChooseUs.css'
import { InfoCard } from './InfoCard'
import discountIcon from '../assets/icons/discount-icon.png'
import royalNavyIcon from '../assets/icons/rn-icon.png'
import nhsIcon from '../assets/icons/nhs-icon.png'
import baIcon from '../assets/icons/ba-icon.png'
import rafIcon from '../assets/icons/raf-icon.png'
import hybridSolIcon from '../assets/icons/hybrid-mort-sol-icon.png'
import advisorIcon from '../assets/icons/exp-mort-advisor-icon.png'
import britFlagIcon from '../assets/icons/brit-flag-icon.png'

export const WhyChooseUs = () => {
	const whyChooseUsData = [
		{
			icon: discountIcon,
			iconAltText: 'discountIcon',
			height: '60px',
			heading: '25% OFF',
			description: 'Discounted rates for NHS, Armed Forces & Existing Clients',
			otherIcons: [
				{
					icon: royalNavyIcon,
					iconAltText: 'royalNavyIcon',
					height: '25px'
				},
				{
					icon: nhsIcon,
					iconAltText: 'nhsIcon',
					height: '25px'
				},
				{
					icon: baIcon,
					iconAltText: 'baIcon',
					height: '25px'
				},
				{
					icon: rafIcon,
					iconAltText: 'rafIcon',
					height: '25px'
				}
			],
			additionalClass: 'info-card-stretched'
		},
		{
			icon: hybridSolIcon,
			iconAltText: 'hybridSolIcon',
			heading: 'Hybrid Solution',
			description: 'Eco-friendly, Cost effective & Convenient'
		},
		{
			icon: advisorIcon,
			iconAltText: 'advisorIcon',
			heading: 'No Key, No Fee!',
			description: 'Everyone Benefits Beyond NHS and Armed Forces'
		},
		{
			icon: britFlagIcon,
			iconAltText: 'britFlagIcon',
			heading: 'Whole of UK',
			description: "Wherever you are, we're here to help"
		}
	]
	return (
		<div id="why-choose-us" className="why-choose-us-container">
			<div className="why-choose-us common--container">
				<div className="why-choose-us__top-section">
					<div className="info-card-heading">
						<h4 className="grey">Features</h4>
						<h3>Why Choose Us?</h3>
					</div>
					<p className="description-text">
						Experience the convenience of a hybrid mortgage solution that saves you time and money, with exclusive benefits for armed forces and NHS members.
					</p>
				</div>
				<div className="why-choose-us__bottom-section">
					<div className="info-cards">
						{whyChooseUsData.map((cardInfo, index) => (
							<InfoCard cardInfo={cardInfo} key={index} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
