import React, { useState } from 'react'
import '../assets/styles/DigitalCalculator.css'
import { TextField } from './input-results/text-field'
import { ResultsField } from './input-results/results-field'

const LABELS = {
	TAGI: 'Total Annual Gross Income',
	TD: 'Total Deposit',
	YCB: 'You could borrow',
	TBPW: 'To buy a property worth',
	LA: 'Loan Amount',
	IR: 'Interest Rate',
	TRM: 'Term',
	CR: 'Capital repayment',
	IO: 'Interest Only',
	PV: 'Property Value',
	FTB: 'First Time Buyer',
	SP: 'Single Property',
	BTL: 'Buy To Let'
}

const calulatorKinds = {
	MORTGAGE: 'mortgage',
	REPAYMENT: 'repayment',
	STAMP_DUTY: 'stamp_duty'
}

const initialDigitalCalculatorState = {
	mortgage: {
		inputs: [
			{
				label: LABELS.TAGI,
				value: '25000',
				icon: '£'
			},
			{
				label: LABELS.TD,
				value: '20000',
				icon: '£'
			}
		],
		results: [
			{
				label: LABELS.YCB,
				value: '112500'
			},
			{
				label: LABELS.TBPW,
				value: '132500'
			}
		]
	},
	repayment: {
		inputs: [
			{
				label: LABELS.LA,
				value: '',
				icon: '£'
			},
			{
				label: LABELS.IR,
				value: '',
				icon: '%'
			},
			{
				label: LABELS.TRM,
				value: '',
				icon: 'yr'
			}
		],
		results: [
			{
				label: LABELS.CR,
				value: '115000'
			},
			{
				label: LABELS.IO,
				value: '140000'
			}
		]
	},
	stamp_duty: {
		inputs: [
			{
				label: LABELS.PV,
				value: '350000',
				icon: '£'
			}
		],
		results: [
			{
				label: LABELS.FTB,
				value: '0'
			},
			{
				label: LABELS.SP,
				value: '5000'
			},
			{
				label: LABELS.BTL,
				value: '15500'
			}
		]
	}
}

const calcStampDuty = (propertyValue, type) => {
	const singlePropertyRate = [0, 5, 10, 12]
	const secondPropertyRate = [3, 8, 13, 15]
	let stampDuty = 0

	let effectiveRate = singlePropertyRate
	if (type === 'second_property') {
		effectiveRate = secondPropertyRate
	}

	// no stamp duty
	if (propertyValue <= 250000) {
		stampDuty += (propertyValue / 100) * effectiveRate[0]
		return stampDuty
	}

	if (propertyValue > 250000) {
		stampDuty += (250000 / 100) * effectiveRate[0]
	}

	// 5%
	if (propertyValue <= 925000) {
		stampDuty += ((propertyValue - 250000) / 100) * effectiveRate[1]
		return stampDuty
	}

	if (propertyValue > 925000) {
		stampDuty += ((925000 - 250000) / 100) * effectiveRate[1]
	}

	// 10%
	if (propertyValue <= 1500000) {
		stampDuty += ((propertyValue - 925000) / 100) * effectiveRate[2]
		return stampDuty
	}

	if (propertyValue > 1500000) {
		stampDuty += ((1500000 - 925000) / 100) * effectiveRate[2]
	}

	// 12 %
	stampDuty += ((propertyValue - 1500000) / 100) * effectiveRate[3]

	return stampDuty
}

export const DigitalCalculator = () => {
	const [selectedCalculator, setSelectedCalculator] = useState(calulatorKinds.MORTGAGE)
	const [digitalCaculatorState, setDigitalCalculatorState] = useState(initialDigitalCalculatorState)

	const findObjectByLabel = (labelToFind) => {
		const foundObject =
			digitalCaculatorState[selectedCalculator].inputs.find((input) => input.label === labelToFind) ||
			digitalCaculatorState[selectedCalculator].results.find((result) => result.label === labelToFind)
		return foundObject
	}

	const onChangeInput = (labeltoUpdate, newValue) => {
		const newDigitalCaculatorState = Object.create(digitalCaculatorState)
		const valueToUpdate = findObjectByLabel(labeltoUpdate)
		valueToUpdate.value = newValue
		setDigitalCalculatorState(newDigitalCaculatorState)
	}

	const calculateMortgage = () => {
		const totalAnnualGrossIncome = findObjectByLabel(LABELS.TAGI).value
		const totalDeposit = findObjectByLabel(LABELS.TD).value
		const GENERAL_RATE = 4.5

		// error handling
		// if(total_agi < 10000 || total_agi > 10000000000 || !total_agi || total_deposit < 10000 || total_deposit > 10000000000 || !total_deposit){}

		const youCouldBorrow = (totalAnnualGrossIncome || 0) * GENERAL_RATE
		const toBuyPropertyWorth = (parseInt(youCouldBorrow) || 0) + (parseInt(totalDeposit) || 0)

		onChangeInput(LABELS.YCB, youCouldBorrow)
		onChangeInput(LABELS.TBPW, toBuyPropertyWorth)
	}

	const calculateRepayment = () => {
		const loanAmount = findObjectByLabel(LABELS.LA).value
		const interestRate = findObjectByLabel(LABELS.IR).value
		const termInYears = findObjectByLabel(LABELS.TRM).value

		// error handling
		// if(loanAmount < 10000 || loanAmount > 10000000000 || !loanAmount || !interestRate || interestRate > 40 || !termInYears || termInYears > 150){}

		const termConvertedToMonths = termInYears * 12
		const interestRatePerMonth = interestRate / 100 / 12

		const discountFactor =
			(Math.pow(1 + interestRatePerMonth, termConvertedToMonths) - 1) / (interestRatePerMonth * Math.pow(1 + interestRatePerMonth, termConvertedToMonths))
		const capitalRepayment = (loanAmount / discountFactor).toFixed(2)
		onChangeInput(LABELS.CR, capitalRepayment)

		const interest_rate = interestRate / 100
		const interestOnly = (loanAmount * interest_rate) / 12
		onChangeInput(LABELS.IO, interestOnly)
	}

	const calculateStampDuty = () => {
		const propertyValue = findObjectByLabel(LABELS.PV).value

		// error handling
		// if(propertyValue > 10000000000){}

		let firstTimeBuyer,
			singleProperty,
			buyToLet = 0

		// first time buyers
		if (propertyValue <= 425000) {
			firstTimeBuyer = 0
		}

		if (propertyValue > 425000 && propertyValue <= 625000) {
			firstTimeBuyer = ((propertyValue - 425000) / 100) * 5
		}

		if (propertyValue > 625000) {
			firstTimeBuyer = calcStampDuty(propertyValue)
		}

		// single property buyers
		singleProperty = calcStampDuty(propertyValue)

		// buy to let buyers
		buyToLet = calcStampDuty(propertyValue, 'second_property')

		onChangeInput(LABELS.FTB, firstTimeBuyer)
		onChangeInput(LABELS.SP, singleProperty)
		onChangeInput(LABELS.BTL, buyToLet)
	}

	const calculate = () => {
		switch (selectedCalculator) {
			case calulatorKinds.MORTGAGE:
				calculateMortgage()
				break
			case calulatorKinds.REPAYMENT:
				calculateRepayment()
				break
			case calulatorKinds.STAMP_DUTY:
				calculateStampDuty()
				break
			default:
				throw Error('Unknown')
		}
	}

	return (
		<section id="digital-calculator" className="digital-calculator">
			<div className="common--container">
				<h3>
					Get Started with our <span className="red">Digital Calculator</span>
				</h3>
				<div className="digital-calculator__interactive">
					{/* tab header */}
					<div className="digital-calculator__options">
						<li className={selectedCalculator === calulatorKinds.MORTGAGE ? 'red selected' : ''} onClick={() => setSelectedCalculator(calulatorKinds.MORTGAGE)}>
							Mortgage
						</li>
						<li className={selectedCalculator === calulatorKinds.REPAYMENT ? 'red selected' : ''} onClick={() => setSelectedCalculator(calulatorKinds.REPAYMENT)}>
							Repayment
						</li>
						<li className={selectedCalculator === calulatorKinds.STAMP_DUTY ? 'red selected' : ''} onClick={() => setSelectedCalculator(calulatorKinds.STAMP_DUTY)}>
							Stamp Duty
						</li>
					</div>

					<div className="digital-calculator__figures">
						<div className="digital-calculator__figures__inputs">
							{digitalCaculatorState[selectedCalculator].inputs.map((input, index) => {
								return <TextField key={index} label={input.label} value={input.value} icon={input.icon} updateField={(e) => onChangeInput(input.label, e.target.value)} />
							})}
						</div>

						<div className="digital-calculator__figures__results-container">
							<div className="digital-calculator__figures__results-container__fields">
								{digitalCaculatorState[selectedCalculator].results.map((input, index) => (
									<ResultsField key={index} label={input.label} value={input.value} />
								))}
							</div>
							<br />
							<button onClick={calculate}>Calculate</button>
						</div>
					</div>
					<p className="digital-calculator__interactive-note">
						These figures are only illustrative. An assessment of your needs will be confirmed before a recommendation can be made. A Key Facts Illustration, which is
						personal to your circumstances, will be provided if a recommendation for a mortgage product is made.
					</p>
				</div>
			</div>
		</section>
	)
}
