import React from 'react'
import '../../assets/styles/input-results/text-field.css'

export const TextField = ({ label, value, icon, updateField }) => {
	return (
		<fieldset className="text-field">
			<p className="text-field__label">{label}</p>
			<div className="text-field__input-container">
				<input className="text-field__input-container__number-input" id="input-agi" type="number" value={value} onChange={updateField} onWheel={(e) => e.target.blur()} />
				<span className="text-field__currency">{icon}</span>
			</div>
		</fieldset>
	)
}
