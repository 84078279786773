import React from 'react'
import '../assets/styles/ForBetterFuture.css'
import { InfoCard } from './InfoCard'
import boltIcon from '../assets/icons/bolt-icon.png'
import ecoIcon from '../assets/icons/eco-icon.png'
import lowerFeeIcon from '../assets/icons/lower-fee-icon.png'
import timeIcon from '../assets/icons/convenient-icon.png'

export const ForBetterFuture = () => {
	const forBetterFutureData = {
		sectionOne: [
			{
				icon: boltIcon,
				iconAltText: 'boltIcon',
				heading: 'Faster processing',
				description: 'Faster processing: The automation of certain tasks speeds up the application and approval process, which means you save time.'
			},
			{
				icon: lowerFeeIcon,
				iconAltText: 'lowerFeeIcon',
				heading: 'Lower Fee',
				description: 'We can offer lower fees to our clients compared to traditional firms.'
			}
		],
		sectionTwo: [
			{
				icon: ecoIcon,
				iconAltText: 'ecoIcon',
				heading: 'Eco-Friendly',
				description: 'Reduce carbon footprint, such as by using electronic documents instead of paper or by reducing the need for clients to travel to a physical office.'
			},
			{
				icon: timeIcon,
				iconAltText: 'timeIcon',
				heading: 'More Convenient',
				description:
					'The ability to apply for a mortgage online from anywhere at any time is more convenient for clients who may not have the time or ability to visit a physical office.'
			}
		]
	}

	return (
		<div id="why-hybrid" className="for-better-future-container">
			<div className="for-better-future common--container">
				<div className="for-better-future__left-section">
					<div className="info-card-heading">
						<h4 className="grey">Why Hybrid?</h4>
						<h3>For Better Future</h3>
						<img src="/assets/active-line.svg" alt="" className="for-better-future__indicator" />
					</div>
					<div className="for-better-future__left-desc">
						<p>By embracing a hybrid model,</p>
						<li>
							<b>We Reduce</b> our carbon footprint.
						</li>
						<li>
							<b>We Save</b> on time, fuel, energy, and other business expenses.
						</li>
						<li>
							<b>We Transfer</b> these benefits to you, by offering lower mortgage advice fees and exceptional value.
						</li>
						<li>
							<b>We Support</b> our heroes by offering exclusive fees, to our NHS and Armed Forces personnel.
						</li>
						<p>Join us in embracing modern and innovative solutions that benefit both you and the environment.</p>
					</div>
				</div>
				<div className="for-better-future__right-section">
					<div className="info-cards">
						<div className="info-cards__section-one">
							{forBetterFutureData.sectionOne.map((cardInfo, index) => (
								<InfoCard cardInfo={cardInfo} key={index} />
							))}
						</div>
						<div className="info-cards__section-two">
							{forBetterFutureData.sectionTwo.map((cardInfo, index) => (
								<InfoCard cardInfo={cardInfo} key={index} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
