import React from 'react'
import '../assets/styles/Home.css'
import { DigitalCalculator } from './DigitalCalculator'
import { ForBetterFuture } from './ForBetterFuture'
import { Hero } from './Hero'
import { WhyChooseUs } from './WhyChooseUs'
import { Pricing } from './Pricing'
import { Navbar } from './Navbar'
import { Footer } from './Footer'

const Home = () => {
	return (
		<>
			<Navbar />
			<div className="home">
				<Hero />
				<WhyChooseUs />
				<ForBetterFuture />
				<DigitalCalculator />
				<Pricing />
			</div>
			<Footer />
		</>
	)
}

export default Home
